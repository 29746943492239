<script setup>
import { create, registerPlugin } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { onMounted, ref } from "vue";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import axios from 'axios';


const uniqueId = ref(`image_uploader_${Math.floor(Math.random() * 10000)}`);

const props = defineProps({
    id: {
        type: String,
        default: "image_uploader_" + Math.floor(Math.random() * 1000) + 1,
        required:false
    },
	 acceptFormat:{
		  type: Array,
        default: [],
	 },
    labelIdle: {
        default:
            "<span class='text-base font-semibold leading-normal text-center text-neutral-500'>Drag & drop or <span class='text-primary-500'>upload an image</span></span>",
        type: String,
    },
    hint: {
        type: String,
        required: false,
    },
    error: {
        type: String,
        required: false,
    },
    confirmation: {
        type: String,
        required: false,
    },
    allowMultipleFiles: {
        type: Boolean,
        default:false,
    },
	 imagePreview: {
        type: Boolean,
        default:true,
    },
    allowReorder: {
        type: Boolean,
        default:false,
    },
	 maxFiles:{
		type:Number,
		default:4,
	 },
    isBrowse: {
        type: Boolean,
        default:false,
    },
});

const pond = ref(null);

const emit = defineEmits(["update:modelValue","progress","uploaded"]);
registerPlugin(FilePondPluginFileValidateType,FilePondPluginImagePreview);
onMounted(() => {
     const s3Client = new S3Client({
        region:  'us-east-1',
        credentials: {
            accessKeyId: 'AKIAQ4NSBPICZSBUJQGS',
            secretAccessKey: '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',
        },
    });


   const fileInput = document.querySelector("#" + props.id);
   pond.value = create(fileInput, {
      storeAsFile: true,
      dropOnPage: true,
      credits: null,
		allowImagePreview:props.imagePreview,
      maxFiles:props.maxFiles,
		acceptedFileTypes: props.acceptFormat,
      allowMultiple: props.allowMultipleFiles,
      labelIdle: props.labelIdle,
      allowReorder: props.allowReorder,
		onaddfileprogress:(file, progress)=>{
			emit('progress',progress);

		},
		onaddfilestart:(file)=>{
			emit('uploaded',file);
		},
      onreorderfiles: (files, origin, target) => {
         emit(
            "update:modelValue",
            files.map(function (filepond) {
                  return filepond.file;
            }),
         );
      }
   });
    pond.value.on("updatefiles", (files) => {
        emit(
        "update:modelValue",
        files.map(function (filepond) {
                return filepond.file;
            }),
        );
    });
});

const clearFiles = () => {
    if (pond.value) {
        pond.value.removeFiles();
    }
};
if(props.isBrowse){
    setTimeout(() => {
        pond.value.browse();
    }, 100);
}

function onSorted(file) {
   console.log(file);
}

function triggerManually(){
    pond.value.browse();
}

defineExpose({
    clearFiles, triggerManually
});
</script>
<template>
    <div class="h-full">
        <div class="h-full">
            <input :id="id" type="file" class="min-h-full" />
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-danger-500">
            {{ props.error }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-primary-500">
            {{ props.hint }}
        </div>
        <div class="mt-[4px] text-xs font-normal leading-none text-success-600">
            {{ props.confirmation }}
        </div>
    </div>
</template>
<style scoped></style>
